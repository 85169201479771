import ButtonCircularProgress from "./ButtonCircularProgress";
import { Box, Typography } from "@material-ui/core";
import React from "react";

export default function DataLoading() {
  return (
    <Box display='flex' justifyContent='center' alignItems='center'>
      <Typography>Loading...</Typography>
      <ButtonCircularProgress />
    </Box>
  );
}
