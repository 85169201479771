import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  InputBase,
  Drawer,
  Grid,
  Link,
  MenuItem,
  Box,
  Container,
  List,
  Typography,
  fade,
  Menu,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ListItem } from "@material-ui/core";
import { UserContext } from "src/context/User";
import { useWeb3React } from "@web3-react/core";
import { ACTIVE_NETWORK, NetworkContextName } from "src/constants";
const headersData = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Support",
    href: "/FAQ",
  },
  {
    label: "Create",
    href: "/create",
  },
  // {
  //   label: "Connect",
  //   href: "/Connect",
  // },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "13px",
    // height: "48px",
    fontWeight: "400",
    borderRadius: 0,
    marginLeft: "16px",
    minWidth: "auto",
    color: "#300760",
    padding: "10px 24px",
    textTransform: "uppercase",
    letterSpacing: "1px",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "1.75",
    },
    "&:active": {
      color: "#222",
    },
    "&:last-child": {
      backgroundColor: "#222",
    },
  },
  toolbar: {
    padding: "0",
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "60px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#000",
    width: "260px",
  },
  drawericon: {
    color: "#000",
    padding: 0,

    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  menuMobile: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",
    borderBottom: "1px solid #3e3e3e",
    padding: "16px",
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "4px 0",
  },
  search: {
    height: "36px",
    border: "1px solid #300760",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 20,
    width: "160px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "257px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    height: "100%",
    color: "#222",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    color: "#222",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
}));

export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = useContext(UserContext);
  const { account, chainId } = useWeb3React();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const {
    header,
    menuMobile,
    menuButton,
    toolbar,
    drawerContainer,
    drawericon,
    logoImg,
    logoDrawer,
    containerHeight,
    search,
    searchIcon,
    mainHeader,
    inputInput,
    inputRoot,
  } = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  useEffect(() => {
    if (user.isLogin) {
      setOpen(false);
    }
  }, [user.isLogin]);

  const displayDesktop = () => {
    return (
      <Container maxWidth='lg' style={{ padding: "0" }}>
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction='row'
            justify='flex-end'
            alignItems='center'
            style={{ paddingLeft: "0px" }}
          >
            <div className={search} style={{ display: "none" }}>
              <div className={searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder='Search…'
                classes={{
                  root: inputRoot,
                  input: inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
            {getMenuButtons()}
            {user.isLogin ? (
              <Button
                variant='contained'
                color='small'
                color='primary'
                aria-controls='simple-menu'
                aria-haspopup='true'
                onClick={handleClick}
              >
                Profile
              </Button>
            ) : (
              <Button
                variant='text'
                color='primary'
                aria-controls='simple-menu'
                aria-haspopup='true'
                onClick={handleClickOpen}
              >
                Connect
              </Button>
            )}
            {/* <Button variant="contained" size="small" color="secondary" onClick={handleClickOpen}>
        Connect
      </Button> */}
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src='images/logo.png' alt='' />

            {getDrawerChoices()}
            {user.isLogin ? (
              <Button
                variant='contained'
                color='small'
                color='primary'
                aria-controls='simple-menu'
                aria-haspopup='true'
                onClick={() => {
                  history.push("/profile");
                }}
              >
                Profile
              </Button>
            ) : (
              <Button
                variant='outlined'
                color='secondary'
                size='small'
                // className="MobileButton"
                onClick={handleClickOpen}
                style={{ marginTop: "15px", marginLeft: "15px" }}
              >
                Connect
              </Button>
            )}
          </div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={10}>
            {/* <div className={search}>
              <div className={searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: inputRoot,
                  input: inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div> */}
          </Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                // edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width='60px'
                height='60px'
                style={{ color: "#300760", fontSize: "35px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Link
            {...{
              component: RouterLink,
              to: href,
              color: "inherit",
              style: { textDecoration: "none" },
              key: label,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Link>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <RouterLink to='/'>
        <Logo className='logoImg' />
      </RouterLink>
    </Box>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: RouterLink,
              className: menuButton,
            }}
          >
            {label}
          </Button>
        </>
      );
    });
  };

  return (
    <>
      <AppBar
        position='relative'
        className={history.location.pathname !== "/" ? "InnerHeader" : "Header"}
        elevation={0}
        style={
          history.location.pathname !== "/"
            ? { backgroundColor: "#e6e6e6" }
            : { backgroundColor: "transparent" }
        }
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "lg" : "lg"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>

      <Menu
        style={{ marginTop: "30px" }}
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose1}
      >
        {user.isLogin ? (
          <>
            <Box style={{ background: "#E3E3E3" }}>
              <MenuItem style={{ minWidth: "150px" }}>
                <RouterLink color='secondary' to='/profile'>
                  My Profile
                </RouterLink>
              </MenuItem>
              <MenuItem style={{ minWidth: "150px" }}>
                <RouterLink color='secondary' to='/my-nft'>
                  My Nft
                </RouterLink>
              </MenuItem>
              <MenuItem
                style={{ minWidth: "150px" }}
                onClick={() => {
                  handleClose1();
                  user.logoutHandler();
                }}
              >
                <RouterLink color='secondary' to='/'>
                  Logout
                </RouterLink>
              </MenuItem>
            </Box>
          </>
        ) : (
          <MenuItem onClick={handleClickOpen}>Connect</MenuItem>
        )}
      </Menu>

      <Dialog
        open={open}
        className='walletdailog'
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' className='dailogTitle'>
          {"Select a Wallet"}
        </DialogTitle>
        <DialogContent className='loginModal'>
          <DialogContentText id='alert-dialog-description'>
            <Box className='walletlist'>
              <List>
                <ListItem>
                  <Link onClick={() => user.connectWallet()}>
                    <Typography variant='h3'>Metamask</Typography>
                    <img src='/images/metamask.png' alt='metamask' />
                  </Link>
                </ListItem>
                {/* <ListItem>
                  <Link href="#">
                    <Typography variant="h3">Wallet Connect</Typography>
                    <img src="/images/wt.png" alt="metamask" />
                  </Link>
                </ListItem> */}
                {/* <ListItem>
                  <Link href="#">
                    <Typography variant="h3">Binance smart</Typography>
                    <img src="/images/binance.png" alt="metamask" />
                  </Link>
                </ListItem> */}
              </List>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions className='dailogFooter'>
          <a onClick={handleClose} color='primary' autoFocus>
            Close
          </a>
        </DialogActions>
      </Dialog>
      {account && (
        <Dialog
          open={chainId !== ACTIVE_NETWORK}
          className='walletdailog'
          // onClose={handleClose}
          // aria-labelledby="alert-dialog-title"
          // aria-describedby="alert-dialog-description"
        >
          <DialogTitle id='alert-dialog-title' className='dailogTitle'>
            {"Network Error"}
          </DialogTitle>
          <DialogContent className='loginModal'>
            <DialogContentText id='alert-dialog-description'>
              <Box className='walletlist'>
                <Typography>Please swich to {NetworkContextName}</Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
