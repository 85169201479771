import React, { Suspense, Fragment, useContext } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import Profilestart from "./views/pages/Profile/Profilestart";
import { routes } from "src/routes";
import { createBrowserHistory } from "history";
// import AuthContext from "src/context/Auth";
import PageLoading from "src/component/PageLoading";
import AuthGuard from "src/component/AuthGuard";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "src/theme";
import UserContext from "src/context/User";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { UserContext as UserAuthContext } from "src/context/User";

const history = createBrowserHistory();

function App() {
  const theme = createTheme();
  //console.log(theme);

  return (
    <div className='App'>
      {" "}
      <ToastContainer />
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <UserContext>
            {/* <AuthContext> */}
            <Router history={history}>
              <RenderRoutes data={routes} />
            </Router>
            {/* </AuthContext> */}
          </UserContext>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

function RenderRoutes(props) {
  const user = useContext(UserAuthContext);
  console.log("user.isLogin", user.isLogin);

  console.log("user.isLoading", user.isLoading);
  return (
    <Suspense fallback={<PageLoading />}>
      {user.isLoading ? (
        <PageLoading />
      ) : (
        <Switch>
          {props.data.map((route, i) => {
            const Component = route.component;
            const Guard = route.guard ? AuthGuard : Fragment;
            const Layout = route.layout || Fragment;
            return (
              <Route
                exact={route.exact}
                key={i}
                path={route.path}
                render={(props) => (
                  <Guard>
                    <Layout>
                      {route.routes ? (
                        <RenderRoutes data={route.routes} />
                      ) : (
                        <Component {...props} />
                      )}
                    </Layout>
                  </Guard>
                )}
              />
            );
          })}
        </Switch>
      )}
    </Suspense>
  );
}
