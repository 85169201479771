import React, { useContext } from "react";
import { Typography, Box, makeStyles, Avatar, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import { BsClockHistory } from "react-icons/bs";
import { sortAddress } from "src/utils";
import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";
import apiConfig from "src/config/ApiConfig";
import { UserContext } from "src/context/User";

const useStyles = makeStyles((theme) => ({
  NftImg: {
    borderRadius: 11,

    display: "block",
    position: "relative",
    "& figure": {
      position: "relative",
      overflow: "hidden",
      height: "185px",
      maxWidth: "100%",
      borderRadius: 11,
      "& img": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: 11,
        // maxHeight: "100%",
        width: "100%",
        boxShadow: "0 11px 24px rgb(0, 0, 0, 0.12)",
      },
    },
  },
  bottomblock: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  },
  bottomTop: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    margin: "10px 0 0",
  },
  playbutton: {
    position: "absolute",
    bottom: 5,
    right: 10,
  },
}));

export default function NFTCard(props) {
  const { type, data, callbackFun } = props;
  const classes = useStyles();
  const user = useContext(UserContext);
  const likeDislikeHandler = async (id) => {
    try {
      const res = await axios.get(apiConfig.likeDislikeNft + id, {
        headers: {
          token: window.sessionStorage.getItem("token")
            ? window.sessionStorage.getItem("token")
            : "",
        },
      });
      if (res.data.response_code === 200) {
        toast.success(res.data.response_message);
        if (callbackFun) {
          callbackFun();
        }
      } else {
        toast.warn(res.data.response_message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Box
      className='bgCardcolor'
      style={{
        borderRadius: 1,
      }}
    >
      <Box display='flex'>
        <Box
          className='collectionSet'
          style={{ padding: "10px", width: "100%" }}
        >
          <Box className='bgcard'>
            <Box className={classes.NftImg}>
              <Link
                to={{
                  pathname: "/nft-details",
                  search: data._id,
                }}
              >
                <figure>
                  <img
                    src={
                      Array.isArray(data.nftId)
                        ? data.nftId[0].thumbNails
                        : data.nftId.thumbNails
                    }
                    alt=''
                  />
                </figure>
              </Link>
            </Box>
            <Box mt={2} className='detailsBox'>
              <Grid container justify='space-between'>
                <Grid item className='NFTDetailsBox'>
                  <Box className='Nftdetail'>
                    <Box container direction='column' alignItems='flex-start'>
                      <Link
                        to={{
                          pathname: "/nft-details",
                          search: data._id,
                        }}
                      >
                        <Typography
                          variant='h2'
                          className='MusicName textEllipsys'
                        >
                          {" "}
                          {Array.isArray(data.nftId)
                            ? data.nftId[0].tokenName
                            : data.nftId.tokenName}
                        </Typography>
                      </Link>

                      <Typography
                        className='textEllipsys dflex justifystart'
                        style={{
                          fontSize: "13px",
                          textAlign: "left",
                          marginTop: "7px",
                        }}
                      >
                        <Avatar
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "5px",
                          }}
                        />
                        {sortAddress(data.userId[0].walletAddress)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className='NftData'>
                    <Grid container direction='column' alignItems='flex-end'>
                      <Grid item>
                        <Box mt={1} className='d-flex'>
                          <BsClockHistory
                            style={{ fontSize: "16px", marginRight: "4px" }}
                          />
                          <span className='bidTime'>
                            {moment(data.expiryTime).endOf().fromNow()}
                          </span>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Box className='FooterData'>
                  <Box>
                    <Typography variant='body2'>Min Bid</Typography>

                    <Typography
                      className='NftBidValue justify-flex-start'
                      variant='body2'
                    >
                      <img src='/images/fuel.png' alt='' />
                      {data.price}
                    </Typography>
                  </Box>
                  <Box className='d-flex justifyEnd text-black' pt={0}>
                    <FavoriteBorderOutlinedIcon
                      style={
                        Array.isArray(data.nftId)
                          ? data.nftId[0].isLike
                            ? { fontSize: "18px", color: "red" }
                            : { fontSize: "18px" }
                          : data.nftId.isLike
                          ? { fontSize: "18px", color: "red" }
                          : { fontSize: "18px" }
                      }
                      onClick={() => {
                        if (user.isLogin) {
                          likeDislikeHandler(
                            Array.isArray(data.nftId)
                              ? data.nftId[0]._id
                              : data.nftId._id
                          );
                        } else {
                          toast.warn("Please login");
                        }
                      }}
                    />
                    <span
                      style={{
                        marginLeft: "10px",
                        fontSize: "18px",
                        fontWeight: "300",
                      }}
                    >
                      {Array.isArray(data.nftId)
                        ? data.nftId[0].likesCount
                        : data.nftId.likesCount}
                    </span>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
