let baseurl = "https://node-polka-marketplace.mobiloitte.com";
// let baseurl = "http://182.72.203.245:1875";
let user = `${baseurl}/api/v1/user`;
let nft = `${baseurl}/api/v1/nft`;

const apiConfig = {
  connectWallet: `${user}/connectWallet`,
  getProfile: `${user}/getProfile`,
  editProfile: `${user}/editProfile`,
  createCollection: `${user}/createCollection`,
  collectionList: `${user}/collectionList`,
  allListHistory: `${user}/allListHistory`,
  allListActivity: `${user}/allListActivity`,
  userDetails: `${user}/userDetails/`,
  collectionDetails: `${user}/collectionDetails/`,

  uploadNFT: `${nft}/uploadNFT`,
  addImage: `${nft}/addImage`,
  addNft: `${nft}/addNft`,
  updateOrder: `${nft}/updateOrder`,
  placeOrder: `${nft}/placeOrder`,
  nftWithoutOrderList: `${nft}/nftWithoutOrderList`,
  placeOrderList: `${nft}/placeOrderList`,
  placeOrderListById: `${nft}/placeOrderListById`,
  placeBid: `${nft}/placeBid`,
  buyOrder: `${nft}/buyOrder`,
  orderListParticular: `${nft}/orderListParticular`,
  rejectBid: `${nft}/rejectBid`,
  acceptBid: `${nft}/acceptBid`,
  bidList: `${nft}/bidList`,
  likeDislikeNft: `${nft}/likeDislikeNft/`,
  cancelOrder: `${nft}/cancelOrder`,
  sendOrderToUser: `${nft}/sendOrderToUser`,
  nftActivityList: `${nft}/nftActivityList`,
};

export default apiConfig;
