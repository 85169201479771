import React, { useContext, useState, useEffect } from "react";
import { Container, Typography, Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import DashboardIcon from "@material-ui/icons/Dashboard";
import FeaturedVideoIcon from "@material-ui/icons/FeaturedVideo";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import { FiEdit } from "react-icons/fi";
import ProfileCard from "src/component/ProfileCard";
import Slider from "react-slick";
import { UserContext } from "src/context/User";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import apiConfig from "src/config/ApiConfig";
import { useWeb3React } from "@web3-react/core";
import DataLoading from "src/component/DataLoading";
import FacebookIcon from "@mui/icons-material/Facebook";
import NFTCard from "src/component/NFTCard";
const useStyles = makeStyles((theme) => ({
  wallet: {
    background: "#fff",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(6),
  },

  iconSocial: {
    ontSize: "15px",
    color: "#999999",
    marginRight: "8px",
  },
  dataIcon: {
    fontSize: "18px",
    color: "#999999",
  },
  dataText: {
    fontSize: "15px",
    lineHeight: "20px",
    fontWeight: "600",
    paddingLeft: "10px",
    color: "#999999",
  },
  dataNumber: {
    float: "right",
    fontSize: "15px",
    lineHeight: "20px",
    fontWeight: "600",
    color: "#999999",
  },
  walletSet: {
    padding: "0 15px 0 0",
    [theme.breakpoints.down("sm")]: {
      padding: "0 15px",
    },
  },
  sectionTitleHead: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    margin: "10px 0 ",
    padding: "0 0",
    width: "100%",
  },
}));
export default function Profile() {
  const user = useContext(UserContext);
  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const settings1 = {
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    className: "recomended",
    autoplay: false,
    autoplaySpeed: 3000,
    infinite: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          centerMode: false,
          centerPadding: "20px",
          autoplay: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          centerMode: false,
          centerPadding: "20px",
          autoplay: false,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          centerPadding: "20px",
          autoplay: false,
        },
      },
    ],
  };
  const token = sessionStorage.getItem("token");
  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    getorderListParticularHandler(cancelTokenSource);
    return () => {
      cancelTokenSource.cancel();
    };
  }, [user]);

  const getorderListParticularHandler = async (cancelTokenSource) => {
    try {
      setOrderList([]);
      const res = await axios.get(apiConfig.orderListParticular, {
        cancelToken: cancelTokenSource && cancelTokenSource.token,

        headers: {
          token,
        },
      });
      if (res.data.response_code === 200) {
        setOrderList(res.data.result);
      }
      setIsLoading(false);
    } catch (error) {
      if (error.message) {
        setIsLoading(false);
      }
      console.log("error", error);
    }
  };

  return (
    <Box className={classes.wallet} mb={5}>
      <Container maxWidth='lg'>
        <Box>
          <Box className='usersView m-b-0'>
            <figure className='figure profilePicture'>
              <img
                src={
                  user.userData?.profileBanner
                    ? user.userData?.profileBanner
                    : "/images/userbg.png"
                }
                className='img-responsive'
                alt=''
              />
            </figure>
            <Box className='editButton'>
              <RouterLink to='/edit-profile' title='Edit Profile'>
                <FiEdit
                  style={{
                    fontSize: "22px",
                    color: "#fff",
                    marginRight: "10px",
                  }}
                />
              </RouterLink>
            </Box>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={3} md={3}>
              <Box className='userPic' style={{ minWidth: 250 }}>
                <figure>
                  <img
                    src={
                      user.userData && user.userData.profilePic
                        ? user.userData.profilePic
                        : "images/profile2.jpg"
                    }
                    alt='error'
                    className='profile2'
                  />
                </figure>
                <Box>
                  <Typography variant='h4' className='SectionTitle m-b-5'>
                    {/* <span className={classes.dataText}> */} Name:
                    <span>{user.userData && user.userData.userName}</span>
                    {/* </span>{" "} */}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='h6' className='SectionTitle m-b-5'>
                    <span className={classes.dataText}>
                      {" "}
                      Email:<span> {user.userData && user.userData.email}</span>
                    </span>
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='h6' className='SectionTitle m-b-5'>
                    <span className={classes.dataText}>
                      {" "}
                      Bio: <span>{user.userData && user.userData.bio}</span>
                    </span>{" "}
                  </Typography>
                </Box>
                <Box>
                  {user.userData.facebookURL && (
                    <FacebookIcon
                      className={classes.iconSocial}
                      onClick={() => {
                        window.open(user.userData.facebookURL);
                      }}
                    />
                  )}
                  {user.userData.twitterURL && (
                    <TwitterIcon
                      className={classes.iconSocial}
                      onClick={() => {
                        window.open(user.userData.twitterURL);
                      }}
                    />
                  )}
                  {user.userData.youtubeURL && (
                    <YouTubeIcon
                      className={classes.iconSocial}
                      onClick={() => {
                        window.open(user.userData.youtubeURL);
                      }}
                    />
                  )}
                </Box>
                {/* <Box className='p-t-20'>
                  <Box className='p-t-5 p-b-10'>
                    <ShoppingCartIcon className={classes.dataIcon} />

                    <span className={classes.dataText}> Weeky Sale </span>

                    <span className={classes.dataNumber}> 25+ </span>
                  </Box>

                  <Box className='p-t-5 p-b-10'>
                    <FeaturedVideoIcon className={classes.dataIcon} />
                    <span className={classes.dataText}>Featured NFT</span>

                    <span className={classes.dataNumber}> 510+ </span>
                  </Box>

                  <Box className='p-t-5 p-b-10'>
                    <LoyaltyIcon className={classes.dataIcon} />

                    <span className={classes.dataText}>NFT on Sale </span>
                    <span className={classes.dataNumber}> 300 </span>
                  </Box>
                  <Box className='p-t-5 p-b-10'>
                    <DashboardIcon className={classes.dataIcon} />
                    <span className={classes.dataText}>Dashboard Item</span>
                    <span className={classes.dataNumber}> 25+ </span>
                  </Box>
                </Box> */}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box mt={3} mb={2}>
        <Container maxWidth='lg'>
          <Grid container spacing={2} style={{ display: "block" }}>
            <Grid container>
              <Box className={classes.sectionTitleHead}>
                <Typography variant='h4' className='SectionTitle m-b-5'>
                  My Orders{" "}
                </Typography>
              </Box>
            </Grid>
            {isLoading ? (
              <DataLoading />
            ) : (
              <Grid container spacing={2}>
                <Slider {...settings1} className='width100'>
                  {orderList.map((data, i) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        key={i}
                        className='walletSet'
                      >
                        <ProfileCard
                          callbackFun={getorderListParticularHandler}
                          data={data}
                          type='auction'
                          index={i}
                        />
                      </Grid>
                    );
                  })}
                </Slider>

                {orderList && orderList.length === 0 && (
                  <Box display='flex' justifyContent='center' pt={5} pb={5}>
                    <Typography
                      style={{ color: "#0c0d31", textAlign: "center" }}
                    >
                      No data found
                    </Typography>
                  </Box>
                )}
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
      <Box mt={3} mb={2}>
        <Container maxWidth='lg'>
          <Grid container spacing={2} style={{ display: "block" }}>
            <Grid container>
              <Box className={classes.sectionTitleHead}>
                <Typography variant='h4' className='SectionTitle m-b-5'>
                  My Owned{" "}
                </Typography>
              </Box>
            </Grid>
            {isLoading ? (
              <DataLoading />
            ) : (
              <Grid container spacing={2}>
                <Slider {...settings1} className='width100'>
                  {user.userData?.orderBuyDetails?.map((data, i) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        key={i}
                        className='walletSet'
                      >
                        <NFTCard
                          callbackFun={() => {
                            user.getProfileHandler(
                              sessionStorage.getItem("token")
                            );
                          }}
                          data={data}
                          type='auction'
                          index={i}
                        />
                      </Grid>
                    );
                  })}
                </Slider>
                {user.userData?.orderBuyDetails &&
                  user.userData?.orderBuyDetails.length === 0 && (
                    <Box display='flex' justifyContent='center' pt={5} pb={5}>
                      <Typography
                        style={{ color: "#0c0d31", textAlign: "center" }}
                      >
                        No data found
                      </Typography>
                    </Box>
                  )}
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
