import React, { useContext } from "react";
import { Typography, Box, makeStyles } from "@material-ui/core";
import { BiTimeFive } from "react-icons/bi";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { UserContext } from "src/context/User";
import axios from "axios";
import apiConfig from "src/config/ApiConfig";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  NftImg: {
    borderRadius: 11,
    cursor: "pointer",
    display: "block",
    position: "relative",
    "& figure": {
      position: "relative",
      overflow: "hidden",
      height: "185px",
      maxWidth: "100%",
      borderRadius: 11,
      "& img": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: 11,
        // maxHeight: "100%",
        width: "100%",
        boxShadow: "0 11px 24px rgb(0, 0, 0, 0.12)",
      },
    },
  },
  bottomblock: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  },
  bottomTop: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    margin: "10px 0 0",
  },
  textField: {
    marginRight: "10px",
    height: "35px",
  },
}));

export default function ProfileCard(props) {
  const { data, callbackFun } = props;
  const classes = useStyles();
  const history = useHistory();
  const user = useContext(UserContext);
  const likeDislikeHandler = async (id) => {
    try {
      const res = await axios.get(apiConfig.likeDislikeNft + id, {
        headers: {
          token: window.sessionStorage.getItem("token")
            ? window.sessionStorage.getItem("token")
            : "",
        },
      });
      if (res.data.response_code === 200) {
        toast.success(res.data.response_message);
        if (callbackFun) {
          callbackFun();
        }
      } else {
        toast.warn(res.data.response_message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <Box className='bgCardcolor' style={{ borderRadius: 1 }}>
      <Box display='flex width100'>
        <Box className='collectionSet' style={{ padding: "17px" }}>
          <Box className='bgcard'>
            <Box
              className={classes.NftImg}
              onClick={() => {
                history.push({ pathname: "/nft-details", search: data._id });
              }}
            >
              <img
                src={data.nftId.thumbNails}
                width='100%'
                alt=''
                style={{ borderRadius: "5px", minHeight: 250, height: "180px" }}
              />
            </Box>

            <Box
              mt={2}
              className='detailsBox'
              onClick={() => {
                history.push({ pathname: "/nft-details", search: data._id });
              }}
            >
              <Box pt={0} display='flex' justifyContent='space-between'>
                <Box>
                  <Typography
                    variant='h6'
                    className='NftHead'
                    style={{ marginBottom: "5px" }}
                  >
                    {data.nftId.tokenName}
                  </Typography>
                </Box>

                <Box mb={1} mt={1} className={classes.bottomblock}>
                  <Typography
                    variant='h5'
                    className='NftExpire'
                    style={{ marginBottom: "5px" }}
                  >
                    <BiTimeFive
                      style={{ fontSize: "13px", marginRight: "5px" }}
                    />
                    {moment(data.expiryTime).endOf().fromNow()}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className='FooterData'>
              <Box>
                <Typography variant='body2'>Min Bid</Typography>

                <Typography
                  className='NftBidValue justify-flex-start'
                  variant='body2'
                >
                  <img src='/images/fuel.png' alt='' />
                  {data.price}
                </Typography>
              </Box>
              <Box className='d-flex justifyEnd text-black' pt={0}>
                <FavoriteBorderOutlinedIcon
                  style={
                    data.nftId.isLike
                      ? { fontSize: "18px", color: "red" }
                      : { fontSize: "18px" }
                  }
                  onClick={() => {
                    if (user.isLogin) {
                      likeDislikeHandler(data.nftId._id);
                    } else {
                      toast.warn("Please login");
                    }
                  }}
                />
                <span
                  style={{
                    marginLeft: "10px",
                    fontSize: "18px",
                    fontWeight: "300",
                  }}
                >
                  {data.nftId.likesCount}
                </span>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
