import React, { createContext, useEffect, useState } from "react";
import { injected } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import apiConfig from "src/config/ApiConfig";

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

const setTokenSession = (token) => {
  if (token) {
    sessionStorage.setItem("token", token);
  } else {
    sessionStorage.removeItem("token");
  }
};

export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate } = useWeb3React();
  const [isLoading, setIsLoading] = useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const [userData, setUserData] = useState();
  const [collectionList, setCollectionList] = useState([]);
  let data = {
    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: () => {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          setIsLoading(false);
          console.log("ERROR", error);
          activate(injected);
        }
      });
    },
    isLogin,
    userData,
    isLoading,
    collectionList,
    getProfileHandler: (token) => getProfileHandler(token),
    getUserCollectionList: (token) => getUserCollectionList(token),
    logoutHandler: () => {
      setUserData();
      setIsLogin(false);
      deactivate();
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("userAddress");
    },
  };
  console.log("ACCOUNT", account);
  useEffect(() => {
    if (account) {
      connectWalletHandler(account);
    } else {
    }
  }, [account]);

  console.log("account", account);

  const connectWalletHandler = async (walletAddress) => {
    try {
      const res = await axios.post(apiConfig.connectWallet, {
        walletAddress,
      });
      if (res.data.response_code === 200) {
        getProfileHandler(res.data.result.token);
        setTokenSession(res.data.result.token);
      } else {
        deactivate();
        setIsLogin(false);
      }
    } catch (error) {
      setIsLoading(false);

      deactivate();
      setIsLogin(false);
      console.log("ERROR", error);
    }
  };

  const getProfileHandler = async (token) => {
    try {
      // const token = sessionStorage.getItem("token");

      const res = await axios.get(apiConfig.getProfile, {
        headers: {
          token,
        },
      });
      if (res.data.response_code === 200) {
        setUserData(res.data.result);
        console.log("res", res);
        getUserCollectionList();

        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLogin(false);
      setIsLoading(false);

      console.log("ERROR", error);
    }
  };

  const getUserCollectionList = async (token) => {
    try {
      const res = await axios.get(apiConfig.collectionList, {
        headers: {
          token: window.sessionStorage.getItem("token")
            ? window.sessionStorage.getItem("token")
            : "",
        },
      });
      if (res.data.response_code === 200) {
        setCollectionList(res.data.result);
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem("userAddress");
    if (userAddress) {
      data.connectWallet();
    } else {
      setIsLoading(false);
      setIsLogin(false);
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
